// Polyfills for IE
// Version 1.3
// - string & array: includes
// - endsWith, startsWith, find
// - indexOf

if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.lastIndexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}

/*! https://mths.be/startswith v0.2.0 by @mathias */
if (!String.prototype.startsWith) {
    (function () {
        'use strict'; // needed to support `apply`/`call` with `undefined`/`null`
        var defineProperty = (function () {
            // IE 8 only supports `Object.defineProperty` on DOM elements
            try {
                var object = {};
                var $defineProperty = Object.defineProperty;
                var result = $defineProperty(object, object, object) && $defineProperty;
            } catch (error) { }
            return result;
        }());
        var toString = {}.toString;
        var startsWith = function (search) {
            if (this == null) {
                throw TypeError();
            }
            var string = String(this);
            if (search && toString.call(search) == '[object RegExp]') {
                throw TypeError();
            }
            var stringLength = string.length;
            var searchString = String(search);
            var searchLength = searchString.length;
            var position = arguments.length > 1 ? arguments[1] : undefined;
            // `ToInteger`
            var pos = position ? Number(position) : 0;
            if (pos != pos) { // better `isNaN`
                pos = 0;
            }
            var start = Math.min(Math.max(pos, 0), stringLength);
            // Avoid the `indexOf` call if no match is possible
            if (searchLength + start > stringLength) {
                return false;
            }
            var index = -1;
            while (++index < searchLength) {
                if (string.charCodeAt(start + index) != searchString.charCodeAt(index)) {
                    return false;
                }
            }
            return true;
        };
        if (defineProperty) {
            defineProperty(String.prototype, 'startsWith', {
                'value': startsWith,
                'configurable': true,
                'writable': true
            });
        } else {
            String.prototype.startsWith = startsWith;
        }
    }());
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });
}

// Production steps of ECMA-262, Edition 5, 15.4.4.14
// Reference: http://es5.github.io/#x15.4.4.14
if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (searchElement, fromIndex) {

        var k;

        // 1. Let o be the result of calling ToObject passing
        //    the this value as the argument.
        if (this == null) {
            throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);

        // 2. Let lenValue be the result of calling the Get
        //    internal method of o with the argument "length".
        // 3. Let len be ToUint32(lenValue).
        var len = o.length >>> 0;

        // 4. If len is 0, return -1.
        if (len === 0) {
            return -1;
        }

        // 5. If argument fromIndex was passed let n be
        //    ToInteger(fromIndex); else let n be 0.
        var n = fromIndex | 0;

        // 6. If n >= len, return -1.
        if (n >= len) {
            return -1;
        }

        // 7. If n >= 0, then Let k be n.
        // 8. Else, n<0, Let k be len - abs(n).
        //    If k is less than 0, then let k be 0.
        k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

        // 9. Repeat, while k < len
        while (k < len) {
            // a. Let Pk be ToString(k).
            //   This is implicit for LHS operands of the in operator
            // b. Let kPresent be the result of calling the
            //    HasProperty internal method of o with argument Pk.
            //   This step can be combined with c
            // c. If kPresent is true, then
            //    i.  Let elementK be the result of calling the Get
            //        internal method of o with the argument ToString(k).
            //   ii.  Let same be the result of applying the
            //        Strict Equality Comparison Algorithm to
            //        searchElement and elementK.
            //  iii.  If same is true, return k.
            if (k in o && o[k] === searchElement) {
                return k;
            }
            k++;
        }
        return -1;
    };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (valueToFind, fromIndex) {

            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            // 1. Let O be ? ToObject(this value).
            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(valueToFind, elementK) is true, return true.
                if (sameValueZero(o[k], valueToFind)) {
                    return true;
                }
                // c. Increase k by 1. 
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}
// File:        iframeServices.js
// Version:     2.0.3 - 2017-06-20
// Description: Provides services for client and server iframe widgets
// 2.0.1 - 2017-04-13 - Add syncCallback
//                    - getStringFromAssocList - use setter only if key in list
//                    - Add isServerReady()
// 2.0.2 - 2017-05-21 - Add (zero) iframe frameBorder
// 2.0.3 - 2017-06-20 - RM  - Use protocol; deal with relative protocol in getTargetOrigin
// 2.0.4 - 2017-08-10 - RM  - Add getDateFromAssocList() to parse dates passed from server
//
function iframeServices($, inputOptions) {

    ifsSelf = this;

    this.options = {
        // Console related stiff
        myId: 'ID', // give host and iframe pagess different valied - CLIENT, SERVER?
        logToConsole: false,
        warnToConsole: false,
        infoToConsole: false,
        // The Id prefixed to messages so that the participants know messages are for them
        // Some other component - e.g. resizer - might be sharing the iframe.
        msgId: '[]',
        primarySplit: "&",
        secondarySplit: "=",

        syncCode: 'sync', // []sync=n .... return []sync=done
        syncPulse: 200, // milliseconds
        syncCallback: undefined,
        syncMax: 0, // no max, continue forever if not done
        syncTrace: false,

        isClient: false, // Set to true for client
        iframe: undefined,
        protocol: "",

    };

    // Run on the client hosting the iframe
    var serverReady = false;
    var syncTimer;
    var syncCount = 0;
    this.isServerReady = function () {
        return serverReady;
    }
    this.sendSync = function() {
        var ops = ifsSelf.options;
        syncCount++;
        if (ops.syncMax > 0 && syncCount > ops.syncMax) {
            return false;
        }
        if (ifsSelf.options.syncTrace) {
            ifsSelf.info("Sending " + syncCount);
        }        
        ifsSelf.sendMessageToIframe("sync=" + syncCount
            + "&info=" + ops.infoToConsole
            + "&warn=" + ops.warnToConsole
            + "&log=" + ops.logToConsole);

        return true;
    }
    this.setSyncTimeout = function () {
        if (!ifsSelf.sendSync()) {
            return; // Exceeded syncMax
        }
        clearTimeout(syncTimer);
        syncTimer = setTimeout(function () {
            if (!serverReady) {
                ifsSelf.setSyncTimeout();
            }
        }, ifsSelf.options.syncPulse);
    }
    this.awaitServerReady = function () {
        ifsSelf.setSyncTimeout();   
    }
    this.checkSync = function(list) {
        ifsSelf.getStringFromAssocList(list, {
            key: 'sync',
            setter: function urlSetter(sync) {
                ifsSelf.info("Sync received: " + sync);
                if (sync === "done") { // client
                    ifsSelf.getStringFromAssocList(list, {
                        key: 'syncTo',
                        setter: function syncTo(syncTo) {
                            ifsSelf.info("syncTo=" + syncTo);
                        }
                    });
                    if (ifsSelf.options.syncCallback !== undefined) {
                        ifsSelf.options.syncCallback();
                    }
                } else { // server - send sync=done
                    ifsSelf.sendMessageToParent("sync=done&syncTo=" + sync);
                }
                serverReady = true;
            }
        });
    }


    if ($ === undefined) {
        $ = {};
        $.extend = Object.assign
    }


    if (this.options !== inputOptions) {
        this.options = $.extend({}, this.options, inputOptions);
    }

    this.log = function (msg) {
        if (this.options.logToConsole && ('object' === typeof window.console)) {
            console.log(this.options.myId + ' ' + msg);
        }
    };

    this.warn = function (msg) {
        if (this.options.warnToConsole && ('object' === typeof window.console)) {
            console.warn(this.options.myId + ' ' + msg);
        }
    };

    this.info = function (msg) {
        if (this.options.infoToConsole && ('object' === typeof window.console)) {
            console.info(this.options.myId + ' ' + msg);
        }
    };


    this.getTargetOrigin = function (remoteHost) {
        if (remoteHost.substring(0, 2) === "//") {
            var protocol = window.location.protocol;
            protocol = (inputOptions.protocol.length > 0) ? inputOptions.protocol : protocol;
            return protocol + remoteHost;
        }
        return ('' === remoteHost || 'file://' === remoteHost) ? '*' : remoteHost;
    };

    this.addEventListener = function (obj, evt, func) {
        /* istanbul ignore else */ // Not testable in PhantonJS
        if ('addEventListener' in window) {
            obj.addEventListener(evt, func, false);
        } else if ('attachEvent' in window) {//IE
            obj.attachEvent('on' + evt, func);
        }
    };

    this.removeEventListener = function (el, evt, func) {
        /* istanbul ignore else */ // Not testable in phantonJS
        if ('removeEventListener' in window) {
            el.removeEventListener(evt, func, false);
        } else if ('detachEvent' in window) { //IE
            el.detachEvent('on' + evt, func);
        }
    };

    this.sendMessageToIframe = function (msg, withInfo) { // called on client
        if (withInfo !== undefined) {
            this.info(msg);
        }
        // will have the options.msgId & options.target after building iframe
        this.options.iframe.contentWindow.postMessage(this.options.msgId + msg, this.options.target);
    };
    this.sendMessageToParent = function (msg, withInfo) { // called on server
        if (withInfo !== undefined) {
            this.info(msg);
        }
        window.parent.postMessage(this.options.msgId + msg, '*');
    };

    this.getParser = function (value) { // Use an anchor as a parser
        var parser = document.createElement('a');
        parser.href = value;
        return parser;
    };

    this.isValidParentUrl = function (value) {
        var currentValue = window.parent.location;
        var current = this.getParser(currentValue);
        var passed = this.getParser(value);
        // same host but not the same url
        return value != currentValue && current.host == passed.host;
    };

    this.isMessageForUs = function (event) {
        return this.options.msgId === ('' + event.data).substr(0, this.options.msgId.length); //''+ Protects against non-string messages
    };

    this.getMessageData = function (event) {
        if (this.isMessageForUs(event)) {
            var messageData = event.data.substring(this.options.msgId.length);
            return messageData;
        }
        return "";
    };

    this.messageListener = function (func) {

        ifsSelf.addEventListener(window, 'message', function receiver(event) {

            var result = ifsSelf.getAssocArrayFromMessage(event, true);
            func(result);
            
        });
    };

    // Split a message of multiple pairs, and return an assoc array
    // primarySplit: "&"
    // primarySplit: "="
    this.getAssocArray = function (data, primarySplit, secondarySplit) {
        if (primarySplit === undefined) {
            primarySplit = this.options.primarySplit;
        }
        if (secondarySplit === undefined) {
            secondarySplit = this.options.secondarySplit;
        }
        var list = [];
        var sVariables = data.split(primarySplit);
        for (var i = 0; i < sVariables.length; i++) {
            var sParameter = sVariables[i].split(secondarySplit);
            list[sParameter[0]] = sParameter[1];
        }
        return list;
    };

    this.getAssocArrayFromMessage = function (event, checkSync) {

        var primarySplit = this.options.primarySplit;
        var secondarySplit = this.options.secondarySplit;
        var list = [];

        if (this.isMessageForUs(event)) {

            var data = event.data.substring(this.options.msgId.length);
           
            var sVariables = data.split(primarySplit);
            for (var i = 0; i < sVariables.length; i++) {
                var sParameter = sVariables[i].split(secondarySplit);
                list[sParameter[0]] = sParameter[1];
            }

            if (checkSync && (!this.options.isClient || !serverReady)) {
                ifsSelf.checkSync(list);                
            }

            return { hasList: true, list: list };
        }
        return { hasList: false, list: null };
    };

    // values = { key: "to", min: 1, max: 15, defaultValue: 0, setter: function(n) { something = n; } }
    this.getNumberFromAssocList = function (list, values) {
        var returnValue = 0;
        if (values === undefined || values.key === undefined) {
            // no default, no setter, so just return 0
            return returnValue;
        }
        returnValue = (values.defaultValue !== undefined) ? values.defaultValue : returnValue;       
        
        if (values.key in list) {
            var value = list[values.key];
            if (!isNaN(value)) {
                var n = parseInt(value);
                if (values.min !== undefined && n < values.min) {
                    n = returnValue; // i.e. defaultValue, or 0
                } else if (values.max !== undefined && n > values.max) {
                    n = returnValue; // i.e. defaultValue, or 0
                } else {
                    returnValue = n;
                }
            }
        }
        if (values.setter !== undefined) {
            values.setter(returnValue);
        }
        return returnValue;
    };

    // Get a date value from the list of passed in values
    this.getDateFromAssocList = function (list, values) {
        var returnValue = new Date();
        if (values === undefined || values.key === undefined) {
            // no default, no setter, so just return 0
            return returnValue;
        }
        returnValue = (values.defaultValue !== undefined) ? values.defaultValue : returnValue;

        if (values.key in list) {
            var value = list[values.key];
            returnValue = Date.parse(value);
        }
        if (values.setter !== undefined) {
            values.setter(returnValue);
        }
        return returnValue;
    };

    // values = { key: "to", defaultvalue: '', setter: function(value) { something = value; } }
    this.getStringFromAssocList = function (list, values) {
        if (values === undefined) {
            return '';
        }
        
        if(values.key === undefined) {
            // no default, no setter, so just return 0
            if (values.setter !== undefined) {
                values.setter('');
            }
            return '';
        }
        var returnValue = (values.defaultValue !== undefined)
            ? values.defaultValue : '';
       
        if (values.key in list) {
            returnValue = list[values.key];
            if (values.setter !== undefined) {
                values.setter(returnValue);
            }
        }
        
        return returnValue;
    };

    // The values.key contains the prefix of a set of keys
    // find all that match, extract the prefix, push onto subList & return subList
    this.getListFromAssocList = function (list, values) {
        var decode = false;
        if (values.decode !== undefined) {
            decode = values.decode;
        }
        var subList = [];
        Object.keys(list).forEach(function (key, index) {
            if (key.startsWith(values.key)) {
                subList.push({
                    code: key.substring(values.key.length),
                    value: decode ? decodeURIComponent(list[key]) : list[key]
                });
            }
        });
        
        return subList;
    }
    
    this.addIframe = function (wrapperId, iframeId, src, inputOptions) {

        var ops = {
            scrolling: 'no',
            display: '', // 'none'
            frameborder: 0,
            allowTransparency: true,
            width: 0,
            height: 0,            
        }
        if (inputOptions !== undefined) {
            ops = Object.assign({}, ops, inputOptions);
        }

        var dest = document.getElementById(wrapperId);

        var iframe = document.createElement('iframe');
        if (ops.display !== '') {
            iframe.style.display = "none";
        }
        iframe.id = iframeId;
        iframe.src = src;
        iframe.scrolling = ops.scrolling;
        iframe.frameBorder = ops.frameborder;
        iframe.width = ops.width;
        if (ops.height !== 'auto') {
            iframe.height = ops.height;
        }
        iframe.allowTransparency = ops.allowTransparency;

        dest.appendChild(iframe);
       

        this.options.iframe = iframe;
        this.options.remoteHost = src.split('/').slice(0, 3).join('/');
        this.options.target = this.getTargetOrigin(this.options.remoteHost);

        return iframe;
    };
};

// File:        domBuilder.js
// Version:     2.0.0
// Author:      R Murphy
// Requires:    
// - jQuery
// - iframeServices-2.0.0.js
// Description: Builds dom into a target
// 
function domBuilder($, ifsInput, inputOptions) {

    var eventHandlers = [];

    var $currentParent;

    var ifs = ifsInput;

    var options = {
        ns: '',
        trace: false
    };

    if (inputOptions !== undefined) {
        options = $.extend({}, options, inputOptions)
    }

    // get namspace string to namespace the id/class etc.
    function getNsString(value) {
        return (options.ns.length > 0)
                ? value.replace("*", options.ns) : value;
    }

    function buildClasses(ns, classes) {
        var classString = '';
        var nsClass =
        classes.forEach(function (c) {
            classString += getNsString(c) + ' ';
        });
        return classString.trim();
    }

    function addAttrs($target, ns, attrs) {
        attrs.forEach(function (attr) {
            $target.attr(attr.name, getNsString(attr.val));
        });
        return $target;
    }

    function addOptionRange(c) {
        for (var i = c.range[0]; i <= c.range[1]; i++) {
            $new = addAttrs($('<' + c.type + '>'), options.ns, c.attrs);
            $new.val(c.val.replace("*", i + ''));
            $new.text(c.text.replace("*", i + ''));
            $new.appendTo($currentParent);
        }
    }

    function addChild(c) {
        if (c.type === undefined) {
            ifs.warn("Missing type for element");
        }
        if (c.type === 'event') {
            return;
        }

        if (options.trace) {
            ifs.info(c.id);
        }
       
        if (c.range !== undefined && c.range.length === 2 && c.type === 'option') {
            addOptionRange(c);
            return;
        }

        var classes = buildClasses(options.ns, c.classes);
        var $new = addAttrs($('<' + c.type + '>'), options.ns, c.attrs)
        .addClass(classes);


        if (c.val !== undefined) {
            $new.val(c.val);
        }
        if (c.text !== undefined) {
            $new.text(c.text);
        }
        var $p = $currentParent;
        $new.appendTo($p);
        $currentParent = $new;
        if (c.children.length > 0) {
            c.children.forEach(addChild);
        }

        if (c.events !== undefined && c.events.length > 0) {
            for (var i = 0; i < c.events.length; i++) {
                eventHandlers.push({ object: $new, event: c.events[i] });
            }
        }

        $currentParent = $p;

    }


    function buildIt(page) {
        if (options.parentId !== undefined && options.parentId.length > 0) {
            page.parentId = options.parentId;
        }
        options.ns = page.ns;

        var id = page.parentId.replace('*', options.ns);
        var $p = $('#' + id);

        $currentParent = $p;
        page.children.forEach(addChild);

        eventHandlers.forEach(function (e) {
            e.object.on(e.event.on, e.event.event);
        });
    }


    return {
        build: buildIt
    }
};


// File:        miniSearchUtilities.js
// Version:     1.0.1 - 2017-06-20
// Author:      R Murphy
// Requires:
// Description: Utility functions:
//              pageLog, clearLog - expects to append <li> to <ul>
//              getNewDateFromString_YYYYMMDD_Dash - gets a date object from specific format
// 1.0.1 - 2017-06-20 - RM  - pageLot & getNewDateFromString_YYYYMMDD_Dash
//
(function (window, document, $) {
    'use strict';

    if (window.miniSearchControl === undefined) {
        window.miniSearchControl = {};
    }

    window.miniSearchControl.utilities = function (inputOptions) {

        var options = {
            logToPage: false,
            IdPageLog: '#pageLog'
        };

        if (inputOptions !== undefined) {
            options = $.extend(true, {}, options, inputOptions);
        }

        var id = options.IdPageLog;

        // Log to a ul in the page
        function pageLog(text) {
            if (options.logToPage && $(id).length) { // Is the element present?
                $('<li>' + text + '</li>').appendTo(id);
            }
        }
        function clearPageLog() {
            if ($(id).length) { // Is the element present?
                $(id).empty();
            }
        }

        $(id).on('click touchstart', function (e) {
            clearPageLog();
        });

        // Expects string date in yyyy-mm-dd where mm or dd can be single digit.
        function getNewDateFromString_YYYYMMDD_Dash(text) {
            var s = text.split("-");
            var newDate = new Date(parseInt(s[0]), parseInt(s[1])-1, parseInt(s[2]));
            return newDate;
        }

        return {
            pageLog: pageLog,
            getNewDateFromString_YYYYMMDD_Dash: getNewDateFromString_YYYYMMDD_Dash
        };

    };

})(window, document, jQuery);

// File:        miniSearchIFrameCommsClient.js
// Version:     1.0.2 - 2017-06-20
// Description: Provides comms services for client and server iframe widgets
// 1.0.2 - 2017-06-20 - RM  - Use utlities.pageLog; add command == "LOG"
//                            Use utilities.getNewDateFromString_YYYYMMDD_Dash for dates
// 1.0.3 - 2018-07-21 - RM  - Added MinDate and MaxDate to config from client, and fixed errors in comms parameter names
// 1.0.4 - 2018-08-10 - RM  - Add use of getDateFromAssocList()
//                            Fix MinDate / MaxDate assignment, which was assigning min to max and max to min.
//
(function ($) {
    'use strict';

    if (window.miniSearchControl === undefined) {
        window.miniSearchControl = {};
    }

    window.miniSearchControl.iframeComms = function (inputs, inputOptions) {

        var ifs = inputs.ifs;

        var funcs = {
            
           // receivedNames: function () { ifs.warn('receivedNames not implemented'); },
            receivedConfig: function () { ifs.warn('receivedConfig not implemented'); },
            receivedResult: function () { ifs.warn('receivedResult not implemented'); },
        };

        funcs = $.extend({}, funcs, inputs.funcs);

        var config = inputs.config;
        var gotConfig = false;
        var options = {};
        if (inputOptions !== undefined) {
            options = $.extend({}, options, inputOptions);
        }

        var utilities = window.miniSearchControl.utilities(options);

        // Run on Client
        function iframeListenerClient(event) {

            var result = ifs.getAssocArrayFromMessage(event, true);
            // utilities.pageLog("Received result");

            if (result.hasList) {

                // utilities.pageLog("Has list");

                var list = result.list;

                ifs.getStringFromAssocList(list, {
                    key: 'command',
                    setter: function urlSetter(command) {
                        if (command === "init" && !gotConfig) {
                            // Server wants us to request config. May have missed our initial request, so send it now                                
                            sendConfigRequestToServer();
                        } else if (command === "config") {
                            gotConfig = true;
                            getConfigFromList(list);
                            //} else if (command === "names") {
                            //    getNamesFromList(list);
                        } else if (command == "POST") {
                            //utilities.pageLog("Received POST");
                            // Done = error or redirect
                            var serverResult = {
                                ErrorMessage: '',
                                resultsUrl: ''
                            };
                            ifs.getStringFromAssocList(list, {
                                key: 'em',
                                setter: function emSetter(message) {
                                    serverResult.ErrorMessage = decodeURIComponent(message);
                                }
                            });
                            ifs.getStringFromAssocList(list, {
                                key: 'path',
                                setter: function pathSetter(path) {
                                    serverResult.resultsUrl = decodeURIComponent(path);
                                }
                            });
                            funcs.receivedResult(serverResult);
                        }
                        else if (command == "LOG") {
                            utilities.pageLog(event.data);
                        }
                    }
                });
            }

        }


        // Run on Client
        function sendConfigRequestToServer() {

            var data = "command=config"
            + "&warn=" + options.warnToConsole
            + "&info=" + options.infoToConsole
            + "&log=" + options.logToConsole;

            ifs.sendMessageToIframe(data);
        }

        // Run on Client
        function sendSearchDataToServer(searchData) {

            var arrive = utilities.getNewDateFromString_YYYYMMDD_Dash(searchData.StartDate);

            var room = searchData.RoomDetails[0];

            var data = "command=POST"
                + "&hc=" + searchData.HotelCode
                + "&s=" + arrive.getFullYear()
                    + "-" + (arrive.getMonth() + 1)
                    + "-" + arrive.getDate()
                + "&n=" + searchData.Nights
                + "&a=" + room.Adults
                + "&c=" + room.Children
                + "&i=" + room.Infants;

            // utilities.pageLog(" send to iframe " + data);

            ifs.sendMessageToIframe(data);

            // utilities.pageLog(" sent to iframe " + data);
        }


        // Run on Client
        function getNamesFromList(list) {

            var validationMessages = [];

            var subList = ifs.getListFromAssocList(list, { key: 'vm', decode: true })

            subList.forEach(function (item, index) {
                validationMessages.push({
                    Name: item.code,
                    Message: item.value
                });
            });

            return validationMessages;
        }
        // Run on Client
        function getConfigFromList(list) {

            var config = {
                Hotels: [],
                ValidationMessages: [],
                ErrorMessage: ''
            };

            ifs.getStringFromAssocList(list, {
                key: 'em',
                decode: true,
                setter: function gotError(message) {

                    ifs.getStringFromAssocList(list, {
                        key: 'enc',
                        decode: true,
                        setter: function gotError(enc) {
                            if (enc === "true") {
                                message = decodeURIComponent(message);
                            }
                        }
                    });

                    config.ErrorMessage = message;
                }
            });

            if (config.ErrorMessage.length === 0) {
                // hc=2&hc1=LWH&hn1=Low%20Wood
                var hotelList = ifs.getListFromAssocList(list, { key: 'hc', decode: true });
                hotelList.forEach(function (item, index) {
                    config.Hotels.push({ HotelCode: item.code, Name: item.value });
                });

                config.SearchDays = ifs.getNumberFromAssocList(list, { key: "sd", defaultValue: 1 });
                config.SearchMonths = ifs.getNumberFromAssocList(list, { key: "sm", defaultValue: 13 });

                config.MinNights = ifs.getNumberFromAssocList(list, { key: "mnn", defaultValue: 1 });
                config.MaxNights = ifs.getNumberFromAssocList(list, { key: "mxn", defaultValue: 14 });
                config.DefaultNights = ifs.getNumberFromAssocList(list, { key: "dn", defaultValue: 1 });
                config.MaxRooms = ifs.getNumberFromAssocList(list, { key: "mxr", defaultValue: 3 });

                config.MinAdults = ifs.getNumberFromAssocList(list, { key: "mna", defaultValue: 0 });
                config.MaxAdults = ifs.getNumberFromAssocList(list, { key: "mxa", defaultValue: 3 });
                config.DefaultAdults = ifs.getNumberFromAssocList(list, { key: "da", defaultValue: 2 });

                config.MaxChildren = ifs.getNumberFromAssocList(list, { key: "mxc", defaultValue: 3 });

                config.MaxInfants = ifs.getNumberFromAssocList(list, { key: "mxi", defaultValue: 3 });

                config.MaxMinorTotal = ifs.getNumberFromAssocList(list, { key: "mxm", defaultValue: 3 });

                config.MinOccupants = ifs.getNumberFromAssocList(list, { key: "mno", defaultValue: 1 });
                config.MaxOccupants = ifs.getNumberFromAssocList(list, { key: "mxo", defaultValue: 3 });

                config.MinDate = ifs.getDateFromAssocList(list, { key: "mnd", defaultValue: config.MinDate });
                config.MaxDate = ifs.getDateFromAssocList(list, { key: "mxd", defaultValue: config.MaxDate });

                config.ValidationMessages = getNamesFromList(list);
            }

            funcs.receivedConfig(config);
        }



        return {
            // CLIENT
            iframeListenerClient: iframeListenerClient,
            sendConfigRequestToServer: sendConfigRequestToServer,
            sendSearchDataToServer: sendSearchDataToServer,

            getConfigFromList: getConfigFromList,
            getNamesFromList: getNamesFromList,
        };

    };

})(jQuery);

// File:        miniSearchPageData.js
// Version:     1.0.2 - 2017-06-14
// Description: provides a data object defining the added DOM for a minisearch
// 1.0.2 - 2017-06-14 - RM  - Add readonly to datepicker
//
(function ($) {
    'use strict';

    if (window.miniSearchControl === undefined) {
        window.miniSearchControl = {};
    }

    window.miniSearchControl.miniSearchPageData = {

        ns: 'miniSearch',
        id: 'MAIN',
        parentId: '*FrameWrapper',
        children: [
            // RowHotel
            {
                id: 'RowHotel',
                type: 'div',
                attrs: [{ name: 'id', val: '*RowHotel' }],
                classes: ['row', '*Row', '*RowHotel'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'FieldHotel',
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldHotel' }],
                        classes: ['*Field', '*FieldHotel'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'Hotels',
                                type: 'select',
                                attrs: [{ name: 'id', val: '*Hotels' },
                                    { name: 'name', val: '*Hotels' },
                                    { name: 'disabled', val: 'disabled' }],
                                classes: ['*DropDown'],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: 'HotelsOptions',
                                        type: 'option',
                                        attrs: [],
                                        classes: [],
                                        val: '',
                                        text: 'Hotel',
                                        children: []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            // RowDates
            {
                id: 'RowDates',
                type: 'div',
                attrs: [{ name: 'id', val: '*RowDates' }],
                classes: ['*Row', '*RowDates'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'FieldDatepicker',
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldDatepicker' }],
                        classes: ['*Field', '*FieldPair', '*FieldDatepicker'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'Datepicker',
                                type: 'input',
                                attrs: [
                                    { name: 'id', val: '*Datepicker' },
                                    { name: 'name', val: '*Datepicker' },
                                    { name: 'type', val: 'text', ns: false },
                                    { name: 'data-date-change-width', val: '800', ns: false },
                                    { name: 'disabled', val: 'disabled' },
                                    { name: 'readonly', val: 'readonly' }
                                ],
                                classes: ['*Datepicker'],
                                val: 'Check in',
                                children: []
                            }
                        ]
                    },
                    {
                        id: 'FieldNights',
                        pause: true,
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldNights' }],
                        classes: ['*Field', '*FieldPair', '*FieldNights'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'Nights',
                                type: 'select',
                                attrs: [{ name: 'id', val: '*Nights' },
                                    { name: 'name', val: '*Nights' },
                                    { name: 'disabled', val: 'disabled' }],
                                classes: ['*DropDown'],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: "NightsOptions",
                                        range: [1, 14],
                                        selectIndex: 0,
                                        type: 'option',
                                        attrs: [],
                                        classes: [],
                                        val: '*',
                                        text: 'Nights (*)',
                                        children: []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            // RowRoom
            {
                id: 'RowRoom',
                type: 'div',
                attrs: [{ name: 'id', val: '*RowRoom' }],
                classes: ['*Row', '*RowRoom'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'FieldAdults',
                        pause: true,
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldAdults' }],
                        classes: ['*Field', '*FieldPair', '*FieldAdults'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'Adults',
                                type: 'select',
                                attrs: [{ name: 'id', val: '*Adults' },
                                    { name: 'name', val: '*Adults' },
                                    { name: 'disabled', val: 'disabled' }],
                                classes: ['*DropDown'],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: "AdultsOptions",
                                        range: [1, 3],
                                        selectIndex: 0,
                                        type: 'option',
                                        attrs: [],
                                        classes: [],
                                        val: '*',
                                        text: 'Adults (*)',
                                        children: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 'FieldChildTotal',
                        pause: true,
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldChildTotal' }],
                        classes: ['*Field', '*FieldPair', '*FieldChildTotal'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'ChildTotal',
                                type: 'select',
                                attrs: [{ name: 'id', val: '*ChildTotal' },
                                    { name: 'name', val: '*ChildTotal' },
                                    { name: 'disabled', val: 'disabled' }],
                                classes: ['*DropDown'],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: "ChildTotalOptions",
                                        range: [0, 3],
                                        selectIndex: 0,
                                        type: 'option',
                                        attrs: [],
                                        classes: [],
                                        val: '*',
                                        text: 'Children (*)',
                                        children: []
                                    }
                                ],
                                events: [
                                    {
                                        id: "ChildTotal-mousedown",
                                        on: 'mousedown',
                                        type: 'event',
                                        //event: function (e) {
                                        //    e.preventDefault();
                                        //    e.stopPropagation();
                                        //    if ($('#miniSearchRowChildren').hasClass('miniSearchHidden')) {
                                        //        $('#miniSearchRowChildren').removeClass('miniSearchHidden')
                                        //    } else {
                                        //        $('#miniSearchRowChildren').addClass('miniSearchHidden')
                                        //    }
                                        //}
                                    }

                                ]
                            }
                        ]
                    }
                ]
            },
            // RowChildren
            {
                id: 'RowChildren',
                type: 'div',
                attrs: [{ name: 'id', val: '*RowChildren' }],
                classes: ['*Row', '*RowChildren', '*Hidden'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'FieldChildren',
                        pause: true,
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldChildren' }],
                        classes: ['*Field', '*FieldPair', '*FieldChildren'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'Children',
                                type: 'select',
                                attrs: [{ name: 'id', val: '*Children' },
                                    { name: 'name', val: '*Children' }],
                                classes: ['*DropDown'],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: "ChildrenOptions",
                                        range: [0, 3],
                                        selectIndex: 0,
                                        type: 'option',
                                        attrs: [],
                                        classes: [],
                                        val: '*',
                                        text: '4 - 15 Years (*)',
                                        children: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 'FieldInfants',
                        pause: true,
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldInfants' }],
                        classes: ['*Field', '*FieldPair', '*FieldInfants'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'Infants',
                                type: 'select',
                                attrs: [{ name: 'id', val: '*Infants' },
                                    { name: 'name', val: '*Infants' }],
                                classes: ['*DropDown'],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: "InfantsOptions",
                                        range: [0, 3],
                                        selectIndex: 0,
                                        type: 'option',
                                        attrs: [],
                                        classes: [],
                                        val: '*',
                                        text: '0 - 3 Yreas (*)',
                                        children: []
                                    }
                                ]
                            }
                        ]
                    },

                ]
            },
            // RowButton
            {
                id: 'RowButton',
                type: 'div',
                attrs: [{ name: 'id', val: '*RowButton' }],
                classes: ['*Row', '*RowButton'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'FieldButton',
                        type: 'div',
                        attrs: [{ name: 'id', val: '*FieldButton' }],
                        classes: ['*Field', '*FieldButton'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'SubmitButton',
                                type: 'input',
                                attrs: [{ name: 'id', val: '*SubmitButton' },
                                        { name: 'type', val: 'submit' }],
                                classes: ['*SubmitButton'],
                                val: 'Check Availability',
                                text: '',
                                children: []
                            }
                        ]
                    }
                ]
            },
            // MultiRoomLink
            {
                id: 'MultiRoom',
                type: 'div',
                attrs: [{ name: 'id', val: '*MultiRoom' }],
                classes: ['*Block', '*MultiRoom'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'FieldButton',
                        type: 'p',
                        attrs: [{ name: 'id', val: '*FieldButton' }],
                        classes: ['*Field', '*FieldButton'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'MultiRoomLink',
                                type: 'a',
                                attrs: [{ name: 'id', val: '*MultiRoomLink' },
                                        { name: 'hrf', val: '#' }],
                                classes: ['*MultiRoomLink'],
                                val: '',
                                text: 'Multiple Room Search',
                                children: []
                            }
                        ]
                    }
                ]
            },
            // RowProgress
            {
                id: 'RowProgress',
                type: 'div',
                attrs: [],
                classes: ['*Block', '*RowProgress'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'UpdateProgress',
                        type: 'div',
                        attrs: [{ name: 'id', val: '*UpdateProgress' },
                                { name: 'hidden', val: 'hidden' }],
                        classes: [],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'UpdateProgressP',
                                type: 'p',
                                attrs: [],
                                classes: [],
                                val: '',
                                text: '',
                                children: [
                                    {
                                        id: 'UpdateProgressImg',
                                        type: 'img',
                                        attrs: [
                                            { name: 'id', val: '*ProgressImg' },
                                            { name: 'alt', val: '' },
                                            { name: 'src', val: '/Images/indicator_mozilla_blu.gif' }],
                                        classes: ['progressIndicator'],
                                        val: '',
                                        text: '',
                                        children: []
                                    },
                                    {
                                        id: 'UpdateProgressSpan',
                                        type: 'span',
                                        attrs: [{ name: 'id', val: '*ProgressSpan' }],
                                        classes: [],
                                        val: '',
                                        text: 'Search in progress...',
                                        children: []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            // ErrorMessageWrapper
            {
                id: 'ErrorMessageWrapper',
                type: 'div',
                attrs: [],
                classes: ['*Block', '*ErrorMessageWrapper'],
                val: '',
                text: '',
                children: [
                    {
                        id: 'ErrorMessage',
                        type: 'div',
                        attrs: [{ name: 'id', val: '*ErrorMessage' }],
                        classes: ['*Error', '*ErrorMessage', '*Hidden'],
                        val: '',
                        text: '',
                        children: [
                            {
                                id: 'ErrorMessageText',
                                type: 'span',
                                attrs: [{ name: 'id', val: '*ErrorMessageText' }],
                                classes: ['*ErrorMessageText'],
                                val: '',
                                text: '',
                                children: []
                            }
                        ]

                    }
                ]
            }
        ]
    };

})(jQuery);

// File:        miniSearchDirect.src.js
// Version:     2.0.6 - 2018-08-14
// Author:      R Murphy
// Requires:
// - iframeServices-2.0.0.js
// - miniSearchIFrameComms.js
// - miniSearchPageData.js
// - domBuilder-2.0.0.js
// Description: Performs minisearch client services. Hidden iframe.
// 2.0.2 - 2017-06-15 - RM  - Add config.firstDay and set it in datepicker on first use.
//                            Add utilities, and touchstart for devices
// 2.0.3 - 2017-06-16 - RM  - Change from iframe .on('load'.. to .bind('load' .. and trigger load with url change
// 2.0.4 - 2017-06-20 - RM  - Add use of protocol to allow forced https; change urls to protocol independence
//                            Use utilities.pageLog; add logToPage option to control it
//                            Use dapeticker('getDate') instead of .val() to get the date in validateSearch
//                            Change location.assign to location.href - ios problem
// 2.0.5 - 2018-08-11 - RM  - Fixed error in datePicker's beforeShow to show the previously selected date on opening.
// 2.0.6 - 2018-08-14 - RM  - Added getGA() and completed and used appendAnalytics() to append analytics param to url.
//

// Glogal function used to get GTM object (use strict doesn't like it internally)
function getGA() {
    try {
        return ga;
    } catch (e) {
        return undefined;
    }
}

window.duetto = {
    appId: 'ca1721',
    tld: 'englishlakes.co.uk',
    queue: []
};

(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'capture.duettoresearch.com/assets/js/duetto/duetto.js'; var n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(s, n); })();

window.runDuettoDenied = function (hotelId, arrive, depart) {
    duetto.queue.push({ 't': 'd', 'h': hotelId, 'sd': arrive, 'ed': depart });
}

var localga = undefined;

(function (window, document, $) {
    'use strict';

    if (window.miniSearchControl === undefined) {
        window.miniSearchControl = {};
    }

    Date.prototype.isValid = function () {
        // An invalid date object returns NaN for getTime() and NaN is the only
        // object not strictly equal to itself.
        return !isNaN(this.getTime());
    };

    window.miniSearchControl.miniSearch = (function (inputOptions) {

        var tube = [];
        var rebuild;

        var options = {
            // for iframeServices
            myId: "CLIENT",
            msgId: "[MiniSearch]",
            logToConsole: false,
            warnToConsole: true,
            infoToConsole: true,
            logToPage: false,

            syncCallback: getConfig,
            syncMax: 10,
            syncTrace: false,
            isClient: true,

            loadingSpanText: "Loading in progress...",
            searchingSpanText: "Search in progress...",

            iframeId: "miniSearchIFrame",
            miniSearchFrameWrapperId: "miniSearchFrameWrapper",
            src: "//www.englishlakeshotels.co.uk/services/MiniSearchDirect/MiniSearchDirectService.htm",
            protocol: "",

            attrHotelCode: "data-hotelcode",
            defaultHotelCode: "",

            resultsUrl: "//www.englishlakeshotels.co.uk/Bookings/Results_Screen.aspx",
            multiRoomSearchUrl: "//www.englishlakeshotels.co.uk/Bookings/Search_Screen.aspx",
            progressImageUrl: "//www.englishlakeshotels.co.uk/Images/indicator_mozilla_blu.gif"

            //datePickerIconHeight: 226, // estimate
            //datePickerIconPath: "/images/calendar_icon.gif",

            //imgSearchEnabledPath: "/css/css-images/buttons/search-enabled.gif",
            //imgSearchEnabledHoverPath: "/css/css-images/buttons/search-enabled-hover.gif",
            //imgContinueSearchEnabledPath: "/css/css-images/buttons/continue-search-enabled.gif",
            //imgContinueSearchEnabledHoverPath: "/css/css-images/buttons/continue-search-enabled-hover.gif",
        };

        if (inputOptions !== undefined) {
            options = $.extend(true, {}, options, inputOptions);
        }

        var mpd = window.miniSearchControl.miniSearchPageData;

        var ifs = new iframeServices($, options);

        var ifc = window.miniSearchControl.iframeComms(
                {
                    ifs: ifs,
                    funcs: {
                        //receivedNames: receivedNames,
                        receivedConfig: receivedConfig,
                        receivedResult: receivedResult,
                    }
                }, options);

        var utilities = window.miniSearchControl.utilities(options);

        ifs.addIframe(options.miniSearchFrameWrapperId, options.iframeId, options.src);

        var domb = domBuilder($, ifs, { parentId: options.miniSearchFrameWrapperId }); //, trace: true });

        var config = {
            // Mode: "CORS", //"LOCAL" or "CORS"

            // Record of dropdowns before dynamic changes
            children: [],
            infants: [],

            GotIt: false, // waiting for config

            ValidationMessages: [],

            firstDay: 1 // 0=today, 1=today+1, etc.
        };

        // Selectors
        var sel = {
            IdMiniSearchWrapper: "#miniSearchFrameWrapper",

            IdMiniSearchRowHotel: "#miniSearchRowHotel",
            IdHotelDropDown: "#miniSearchHotels",

            IdMiniSearchRowDates: "#miniSearchRowDates",
            IdNightsDropDown: "#miniSearchNights",

            IdAdultsDropDown: "#miniSearchAdults",
            IdMiniSearchChildTotal: "#miniSearchChildTotal",

            IdRowChilden: "#miniSearchRowChildren",
            IdChildrenDropDown: "#miniSearchChildren",
            IdInfantsDropDown: "#miniSearchInfants",


            IdDatePicker: "#miniSearchDatepicker",
            IdSearchButton: "#miniSearchSubmitButton",
            IdMultiRoomLink: "#miniSearchMultiRoomLink",
            IdHiddenContinue: "#miniSearchContinue",
            
            IdMiniSearchErrorMessage: "#miniSearchErrorMessage",
            IdMiniSearchErrorMessageText: "#miniSearchErrorMessageText",

            IdProgress: '#miniSearchUpdateProgress',
            IdProgressSpan: '#miniSearchProgressSpan',
            IdProgressImg: '#miniSearchProgressImg',

            // class values

            // Some css implementation for hiding when this class is present
            ClassMiniSearchHidden: "miniSearchHidden",

            // attribute names

            // An attribute on hotel dropdown select element of 
            AttrFixedHotelCode: "Data-FixedHotelCode",

            // See getDateFormat
            AttrDateChangeWidth: "data-date-change-width",
        };

        // Names in config.ValidationMessages
        // See findValidationMessage
        var validationNames = {
            UnselectedHotel: "UnselectedHotel",
            InvalidHotel: "InvalidHotel",
            TooManyRooms: "TooManyRooms",
            InvalidOccupants: "InvalidOccupants",
            InvalidAdults: "InvalidAdults",
            InvalidTotalMinors: "InvalidTotalMinors",
            InvalidChildren: "InvalidChildren",
            InvalidInfants: "InvalidInfants",
            SearchDays: "SearchDays",
            SearchMonths: "SearchMonths",
            InvalidDate: "InvalidDate",
            InvalidNights: "InvalidNights"
        };

        // Search data to be built and passed to
        var searchData = {
            Id: "", // Id of the element that was the source of the data (i.e. the element whose event is handled)
            Command: "",

            // Search data
            HotelCode: "",
            DayOfMonth: 1,
            YearMonth: '2014-01',
            StartDate: '2014-01-01',
            Nights: 2,

            Rooms: '1',
            RoomDetails: [
                { Adults: 2, Children: 0, Infants: 0 }
            ],
            TestAvailFile: "",

            // Returned by server
            Url: "",
            ErrorMessage: "",
            ResultsPath: ""
        };


        // pickup the analytics qs and append to location
        function appendAnalytics(location) {
            localga = getGA();
            if (typeof localga != "undefined" && typeof localga.getAll === "function") {
                var linkerParam = (localga.getAll()[0]).get('linkerParam');
                location += (location.includes("?") ? "&" : "?") + linkerParam;                        
            }
            return location;
        }

        // Append an option to a select
        function appendOption(selector, value, text, selected) {
            var option = '<option value="' + value + '" '
                + ((selected !== undefined && selected) ? 'selected="selected"' : '')
                + '>'
                + text + '</option>';

            $(selector).append(option);
        }

        function showErrorMessage(errorMessage) {
            if (errorMessage && errorMessage.length > 0) {
                $(sel.IdMiniSearchErrorMessageText).text(errorMessage).show();
                $(sel.IdMiniSearchErrorMessage).removeClass(sel.ClassMiniSearchHidden);
                localDuettoDenied();
            }
            else {
                $(sel.IdMiniSearchErrorMessageText).text("").hide();
                $(sel.IdMiniSearchErrorMessage).addClass(sel.ClassMiniSearchHidden);
            }
        }

        function localDuettoDenied() {

            if (window.runDuettoDenied !== undefined) {

                var hotelCode = $(sel.IdHotelDropDown).val();
                if (hotelCode === undefined || hotelCode === null || hotelCode.length === 0)
                    return;

                var selectedDate = $(sel.IdDatePicker).val();
                var date = new Date(selectedDate);
                if (!date.isValid()) {
                    return;
                    // date = new Date();
                }

               
                var nights = parseInt($(sel.IdNightsDropDown).val(), 10);

                var departDate = addDays(new Date(date), nights);

                var arrive = date.getFullYear()
                    + "-" + (date.getMonth() + 1)
                    + "-" + date.getDate();

                var depart = departDate.getFullYear()
                    + "-" + (departDate.getMonth() + 1)
                    + "-" + departDate.getDate();

                //var arrive = $(sel.IdDatePicker).datepicker.formatDate('yy-mm-dd', new Date(date));
                //var depart = $(sel.IdDatePicker).datepicker.formatDate('yy-mm-dd', new Date(departDate));

                // Output the Duetto denied data
                window.runDuettoDenied(hotelCode, arrive, depart);
            }
        }

        function addDays(date, days) {            
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        function clearErrorMessage() {
            showErrorMessage("");
        }
        function showProgress(message) {
            if (message !== undefined) {
                $(sel.IdProgressSpan).text(options.loadingSpanText);
            } else {
                $(sel.IdProgressSpan).text(options.searchingSpanText);
            }

            $(sel.IdProgress).show().removeClass(sel.ClassMiniSearchHidden);
        }
        function hideProgress() {
            $(sel.IdProgress).hide().addClass(sel.ClassMiniSearchHidden);
        }

        function setDefaultHotelCode() {
            // Select a specific hotel if required, and hide the select            
            if (options.defaultHotelCode.length > 0) {
                $(sel.IdHotelDropDown)
                    .val(options.defaultHotelCode)
                    .prop('selected', true);

                $(sel.IdMiniSearchRowHotel).addClass(sel.ClassMiniSearchHidden);
            }
        }

        // Populate the dropdowns based on the config supplied
        function populateControls() {

            // Populate the hotel select
            $(sel.IdHotelDropDown).empty();
            appendOption(sel.IdHotelDropDown, "", "Hotel");
            config.Hotels.forEach(function (h) {
                appendOption(sel.IdHotelDropDown, h.HotelCode, h.Name);
            });

            setDefaultHotelCode();

            $(sel.IdNightsDropDown).empty();
            for (var n = config.MinNights; n <= config.MaxNights; n++) {
                appendOption(sel.IdNightsDropDown, n, 'Nights (' + n + ')', config.DefaultNights === n);
            }

            $(sel.IdAdultsDropDown).empty();
            for (var n = config.MinAdults; n <= config.MaxAdults; n++) {
                appendOption(sel.IdAdultsDropDown, n, 'Adults (' + n + ')', config.DefaultAdults === n);
            }

            $(sel.IdMiniSearchChildTotal).empty();
            for (var n = 0; n <= config.MaxMinorTotal; n++) {
                appendOption(sel.IdMiniSearchChildTotal, n, 'Children (' + n + ')');
            }

            $(sel.IdChildrenDropDown).empty();
            for (var n = 0; n <= config.MaxChildren; n++) {
                appendOption(sel.IdChildrenDropDown, n, '4 - 15 Years (' + n + ')');
            }

            $(sel.IdInfantsDropDown).empty();
            for (var n = 0; n <= config.MaxInfants; n++) {
                appendOption(sel.IdInfantsDropDown, n, '0 - 3 Years (' + n + ')');
            }

        }

        var holdMessage = false;

        var checkMessage = function () {
            holdMessage = false;
            if ($(sel.IdHotelDropDown).val() !== "LWH") {
                return;
            }

            var nights = parseInt($(sel.IdNightsDropDown).val());
            var selectedDate = $(sel.IdDatePicker).val();
            if (selectedDate === "Check in") {
                return;
            }
            var arrive = new Date(selectedDate);
            var depart = addDays(arrive, nights);

            var da = arrive.getTime();
            var dd = depart.getTime();

            var d1 = (new Date("2020-12-03")).getTime();
            var d2 = (new Date("2020-12-21")).getTime();

            if (!(da > d2 || dd < d1)) {
                showErrorMessage("PLEASE NOTE: During this date range the indoor spa facilities are being maintained " +
                    "- expected completion 21st Dec. All other facilities open.");
                holdMessage = true;
            }
        }

        // After a successful GET
        function setupHandlers() {

            // Grab texts from children & infants selects
            // Used to rebuild options, to be less than config.MaxMinorTotal
            $(sel.IdChildrenDropDown + " > option").each(function () {
                config.children.push($(this).text());
            });
            $(sel.IdInfantsDropDown + " > option").each(function () {
                config.infants.push($(this).text());
            });

            $(sel.IdHotelDropDown).on('click', function (event) {              
                clearMessageAndHeight();
            });
            $(sel.IdHotelDropDown).on('change', function (event) {
                checkMessage();
            });

            $(sel.IdNightsDropDown).on('click', function () {
                clearMessageAndHeight();
            });
            $(sel.IdNightsDropDown).on('change', function () {
                checkMessage();
            });
            $(sel.IdAdultsDropDown).on('click', function () {
                clearMessageAndHeight();
            });
            $(sel.IdChildrenDropDown).on('click', function () {
                clearErrorMessage();
            });
            $(sel.IdInfantsDropDown).on('click', function () {
                clearErrorMessage();
            });


            // Show/Hide children details
            $(sel.IdMiniSearchChildTotal).on('mousedown touchstart', function (event) {
                event.preventDefault();
                //event.stopPropagation();
                clearErrorMessage();
                flipChildren();
            });

            // Dynamically change the options for children/infants to allow a total of max
            $(sel.IdChildrenDropDown).change(function () {
                adjustChildDropdown(this, sel.IdInfantsDropDown);
            });
            $(sel.IdInfantsDropDown).change(function () {
                adjustChildDropdown(this, sel.IdChildrenDropDown);
            });

            $(sel.IdDatePicker).datepicker({
                dateFormat: getDateFormat(),
                minDate: getMinDate(),
                maxDate: getMaxDate(),
                onSelect: function () {
                    $(this).datepicker('option', { dateFormat: getDateFormat() });
                    checkMessage();
                },
                beforeShow: function dpBeforeShow() {
                    clearErrorMessage();
                    hideChildren();
                    var currentDate = $(sel.IdDatePicker).datepicker("getDate");
                    if (currentDate >= getMinDate() && currentDate <= getMaxDate()) {
                        $(sel.IdDatePicker).datepicker("setDate", currentDate);
                    } else {
                        $(sel.IdDatePicker).datepicker("setDate", getMinDate());
                    }
                }
            });

            $(sel.IdHotelDropDown).removeAttr('disabled');
            $(sel.IdNightsDropDown).removeAttr('disabled');
            $(sel.IdMiniSearchChildTotal).removeAttr('disabled');
            $(sel.IdDatePicker).removeAttr('disabled');
            $(sel.IdAdultsDropDown).removeAttr('disabled');
            
            hideProgress();

            $(sel.IdSearchButton).on('click touchstart', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var error = validateSearch();

                if (error.length > 0) {
                    showErrorMessage(error);
                } else {
                    clearErrorMessage();
                    sendSearchData();
                }
            });
        }


        function getMinDate() {
            return new Date(config.MinDate);
        }
        function getMaxDate() {
            return new Date(config.MaxDate);
        }

        // Date format depends on available space
        function getDateFormat() {
            var value = $(sel.IdDatePicker).attr('data-date-change-width');
            var dateChangeWidth = parseInt(value, 10);
            var width = $(window).width();
            if (width > dateChangeWidth) {
                return 'd MM yy';
            }
            return 'd M yy';
        }

        // Refill the other (child/infant) dropdown according to
        // how many children+infants in total are allowed.
        function adjustChildDropdown(that, otherId) {

            // Get the selected values of the two dropdowns
            var nThat = parseInt($(that).val(), 10); // That one
            var nOther = parseInt($(otherId).val(), 10); // The other

            // What's the max for the other, given total MaxMinorTotal?
            var nMax = config.MaxMinorTotal - nThat;

            // Clear its options ...
            $(otherId).empty();

            var a = [];

            if (otherId === sel.IdChildrenDropDown) {
                a = config.children;
            } else {
                a = config.infants;
            }

            // Then add to nMax, re-selecting the previously selected
            // We have Max but assume Min=0 for children
            for (var i = 0; i <= nMax; i++) {
                appendOption(otherId, i, a[i], (i === nOther));
            }

            // Set the  total children box to the sum
            $(sel.IdMiniSearchChildTotal)
                .val((nThat + nOther).toString()).change();
        }

        // Get the named message from the WBS supplied messages
        function findValidationMessage(name) {

            var validationMessage = config.ValidationMessages
               .find(function (element, index, messages) {

                   return element.Name === name;

               });

            if (validationMessage === undefined) {
                return name;
            }
            return validationMessage.Message;
        }

        // The validation here should be completed to comply with the expected 
        // mini search validation rules (supplied separately)
        // If a validation framework such as jquery-validation is used, extract the details from here.
        function validateSearch() {

            // Hotel
            var hotelCode = $(sel.IdHotelDropDown).val();
            if (hotelCode === "") {
                return findValidationMessage(validationNames.UnselectedHotel);
            } else if (config.Hotels.filter(function (e) { return e.HotelCode === hotelCode; }).length <= 0) {
                return findValidationMessage(validationNames.InvalidHotel);
            }

            // Arrive
            var date = $(sel.IdDatePicker).datepicker('getDate');
            var time = date.getTime();
            if (!date.isValid()) {
                return findValidationMessage(validationNames.InvalidDate);
            } else if (getMinDate().getTime() > time) {
                return findValidationMessage(validationNames.SearchDays);
            } else if (time > getMaxDate().getTime()) {
                return findValidationMessage(validationNames.SearchMonths);
            }

            // Nights
            var nights = parseInt($(sel.IdNightsDropDown).val(), 10);
            if (!(config.MinNights <= nights && nights <= config.MaxNights)) {
                return findValidationMessage(validationNames.InvalidNights);
            }

            // ONE NIGHT SATURDAY NOT CHECKED HERE

            // Adults
            var adults = parseInt($(sel.IdAdultsDropDown).val(), 10);
            if (!(config.MinAdults <= adults && adults <= config.MaxAdults)) {
                return findValidationMessage(validationNames.InvalidAdults);
            }

            // Children
            var children = parseInt($(sel.IdChildrenDropDown).val(), 10);
            if (!(0 <= children && children <= config.MaxChildren)) {
                return findValidationMessage(validationNames.InvalidChildren);
            }

            // Infants
            var infants = parseInt($(sel.IdInfantsDropDown).val(), 10);
            if (!(0 <= infants && infants <= config.MaxInfants)) {
                return findValidationMessage(validationNames.InvalidInfants);
            }

            // Minors Total
            var minors = infants + children;
            if (!(0 <= minors && minors <= config.MaxMinorTotal)) {
                return findValidationMessage(validationNames.InvalidTotalMinors);
            }

            // Occupants
            var occupants = adults + infants + children;
            if (!(config.MinOccupants <= occupants && occupants <= config.MaxOccupants)) {
                return findValidationMessage(validationNames.InvalidOccupants);
            }

            return "";
        }

        function hideChildren(resize) {
            if (!$(sel.IdRowChilden).hasClass(sel.ClassMiniSearchHidden)) {
                $(sel.IdRowChilden).addClass(sel.ClassMiniSearchHidden);                
            }
        }

        // show/hide children
        // childrenClicked - bool 
        // - true if actually clicked
        // - false if ensuring hidden
        function flipChildren() {

            if ($(sel.IdRowChilden).hasClass(sel.ClassMiniSearchHidden)) {
                // Showing children only if intended
                $(sel.IdRowChilden).removeClass(sel.ClassMiniSearchHidden);
                $(sel.IdChildrenDropDown).focus();
            } else {
                hideChildren(true);
                // Focus on total children
                $(sel.IdMiniSearchChildTotal).focus();
            }
        }

        // Convenient combo for clicking hotel, nights, adults
        function clearMessageAndHeight() {
            if (holdMessage) {
                return;
            }
            clearErrorMessage();
            hideChildren(true);
        }

        // Perform the availability search
        function sendSearchData() { // 1

            showProgress();

            var selectedDate = $(sel.IdDatePicker).val();
            var date = new Date(selectedDate);
            if (!date.isValid()) {
                date = new Date();
            }
            searchData.Command = "POSTMINI";
            searchData.HotelCode = $(sel.IdHotelDropDown).val();
            searchData.StartDate = date.getFullYear()
                + "-" + (date.getMonth() + 1)
                + "-" + date.getDate();

            searchData.DayOfMonth = date.getDate();
            searchData.YearMonth = date.getFullYear() + "-" + (date.getMonth() + 1);

            searchData.Nights = parseInt($(sel.IdNightsDropDown).val(), 10);
            searchData.RoomDetails[0].Adults = parseInt($(sel.IdAdultsDropDown).val(), 10);
            searchData.RoomDetails[0].Children = parseInt($(sel.IdChildrenDropDown).val(), 10);
            searchData.RoomDetails[0].Infants = parseInt($(sel.IdInfantsDropDown).val(), 10);

            ifc.sendSearchDataToServer(searchData);
        }

        function buttonMouseOut() {

            var value = $(sel.IdHiddenContinue).val();
            if (value === "Continue") {
                $(sel.IdSearchButton).attr('src', options.imgContinueSearchEnabledPath);
            }
            else {
                $(sel.IdSearchButton).attr('src', options.imgSearchEnabledPath);
            }
        }

        function successfulPost(list) {
            ifs.getStringFromAssocList(list, {
                key: 'em', // ErrorMessage
                setter: function emSetter(errorMessage) {
                    showErrorMessage(errorMessage);
                }
            });
            ifs.getStringFromAssocList(list, {
                key: 'rp', // ResultPath
                setter: function urlSetter(resultPath) {
                    //window.location.assign(resultPath);
                    var url = appendAnalytics(resultPath);
                    window.location.href = url;
                }
            });
        }

        function receivedConfig(serverConfig) {

            hideProgress();

            config = $.extend({}, config, serverConfig);
            config.GotIt = true;

            if (config.ErrorMessage.length > 0) {
                showErrorMessage(config.ErrorMessage);
            } else {
                populateControls(config);
                setupHandlers();
            }
        }

        // The results are only not positive (no availability) if there's an error message,
        // or if there's no resultsUrl
        function receivedResult(serverResult) {

            hideProgress();

            if (serverResult.ErrorMessage.length > 0) {
                showErrorMessage(serverResult.ErrorMessage);
            } else {
                clearErrorMessage();
            }
            if (serverResult.resultsUrl.length > 0) {

                // Todo - Patch to fix adults in query string
                serverResult.resultsUrl = patchQueryString(serverResult.resultsUrl);

                // Append Analytics
                var url = appendAnalytics(serverResult.resultsUrl);
                window.location.href = url; //serverResult.resultsUrl;
            }
        }

        // After sync, call this (set options.syncCallback: getConfig )
        function getConfig() {
            ifc.sendConfigRequestToServer();
        }


        function patchQueryString(url) {
            var adultsp = "&adults";

            if (url.includes(adultsp)) {
                var idx = url.indexOf(adultsp);
                if (idx > 0) {
                    var left = url.substring(0, idx + adultsp.length);
                    var right = url.substring(idx + adultsp.length);
                    if (right.substring(0, 1) === '=') {
                        // OK, return url
                        return url;
                    }
                    // Insert '='
                    return left + '=' + right;
                }
            }
            // No adults in request
            return url;
        }

        function init() { // end

            ifs.info("INIT");
            // utilities.pageLog("INIT");
            if (options.load !== undefined) {
                $('#' + options.miniSearchFrameWrapperId).load(options.load);
            } else {
                domb.build(mpd);
            }

            $(sel.IdMultiRoomLink).on('click touchstart', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var url = appendAnalytics(options.multiRoomSearchUrl);
                window.parent.location.assign(url);
            });

            $(sel.IdProgressImg).attr('src', options.progressImageUrl);

            // Can't show this until dom is present
            showProgress("loading");

            // GET DEFAULT HOTEL CODE!
            var hotelCode = $("#" + options.miniSearchFrameWrapperId).attr(options.attrHotelCode);
            if (hotelCode !== undefined && hotelCode.length > 0) {
                options.defaultHotelCode = '' + hotelCode;
            }
            // It might be set above, or in the options
            searchData.HotelCode = options.defaultHotelCode;
            if (searchData.HotelCode.length > 0) {
                $(sel.IdMiniSearchRowHotel).addClass('miniSearchHidden');
            }

            ifs.addEventListener(window, 'message', ifc.iframeListenerClient);
         
            var iFrame = $('#' + options.iframeId);

            ifs.info("bind to iframe load");
            $(iFrame).bind('load', function () { // change for jquery 3.2.1
                // Sends sync messages to server until ready, then getConfig()
                ifs.info("WAIT SERVER");
                ifs.awaitServerReady();
            });

            ifs.info("trigger iframe load");
            var newSrc = iFrame.attr('src') + '?c=' + Math.random(); //force new URL
            ifs.info("prior url " + newSrc);
            var url = document.createElement("a");
            url.href = newSrc;
            if (options.protocol.length > 0) {
                url.protocol = options.protocol;
            }
            ifs.info("post url " + url.toString());
            
            iFrame.attr('src', url.toString()); //newSrc); //changing the src triggers the load event
        }

        return {
            init: init
        };

    })(window.miniSearchFrameOptions !== undefined ? window.miniSearchFrameOptions   : undefined);

    $(document).ready(function () {
        window.miniSearchControl.miniSearch.init();
    });

})(window, document, jQuery);
